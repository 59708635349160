import { SiteLink, Swiper } from 'components/shared';
import { useFetchContentfulProductsInShopify } from 'hooks/shop/useFetchContentfulProductsInShopify';
import Product from 'components/shared/product';
import { getLinkHref } from 'lib/utils/helpers';
import { getResourceText } from 'lib/resources';
import classNames from 'classnames';
import validateRequired from './validate-required';
import withPropsValidation from './with-props-validation.tsx';

const AdaptiveSwiper = ({ children }) => (
  <Swiper
    className="relative w-full"
    slidesPerView={1.2}
    spaceBetween={10}
    slidesOffsetBefore={15}
    slidesOffsetAfter={15}
    breakpoints={{
      768: {
        slidesPerView: 3.5,
      },
    }}
  >
    {children}
  </Swiper>
);

const NoSwiper = ({ children }) => (
  <div className="w-100% grid grid-cols-2 gap-x-[7px] gap-y-6 md:grid lg:grid-cols-4 lg:grid-rows-1 lg:gap-x-2 lg:gap-y-0">
    {children}
  </div>
);

const Section = ({ data: { title, productsCollection, link, collectionResources } }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: productsCollectionList } = useFetchContentfulProductsInShopify(
    productsCollection?.items || []
  );

  const t = getResourceText(collectionResources);

  const Container = productsCollectionList?.length > 4 ? AdaptiveSwiper : NoSwiper;

  return (
    <div
      className={classNames(
        'relative w-full pb-9 text-blue antialiased',
        title || link ? 'py-[50px]' : ''
      )}
    >
      {(title || link) && (
        <div className="mb-[40px] grid grid-cols-[2fr_1fr] items-end gap-[2.5rem] px-[0.94rem] lg:px-[32px]">
          {title && <p className="text-heading-3">{title}</p>}
          {link && (
            <SiteLink
              href={getLinkHref(link.link)}
              external={link.externalLink}
              query={link.query}
              className="justify-self-end text-right text-[0.625rem] font-normal uppercase leading-[0.5rem] underline"
            >
              {link.text}
            </SiteLink>
          )}
        </div>
      )}
      <Container col={productsCollectionList.length}>
        {productsCollectionList.map(product => (
          <Product className="w-full" product={product} key={product.id} t={t} />
        ))}
      </Container>
    </div>
  );
};

export default withPropsValidation(Section, ({ data: { name, productsCollection } }) =>
  validateRequired({ Name: name, Products: productsCollection.items })
);
